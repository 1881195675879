export const shops = [
    {
      name: "",
      autoOpen: "false",
      url: 'Images/Shops/Nile/cards_light_icon.png',
      background: "#DFD3C3",
      maxHeight: "79vh",
      borderRadius: "20px 0px 0px 20px",
      details: [ 
        {//Nile
          name: "Nile",
          background: "#F8EDE3",
          subShops: [
            {//TemelDesign1
              name: "Design Cluster 1",
              description: "Design Cluster 1",
              background: "#DFD3C3",
              hoverImageUrl: "Images/Shops/Nile/DesignCluster1/NILEDizaynKart.jpg",
              subShops: [
                {//design1
                  name:"Design 1",
                  description: "Design1",
                  maxHeight: "400px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/DesignCluster1/Design1/Design1.svg',
                  
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 1",
                    url: 'Images/Shops/Nile/DesignCluster1/Design1/Design1.svg',
                    height1: 360,
                    width1:300,
                    x1: 252,
                    y1: 150,
                    height2: 360,
                    width2:300,
                    x2: 576,
                    y2: 150,
                    textHeight1: 400,
                    textHeight2: 400,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                    text2Visible: 'true',
                    designType: 'Double',
                  },
                ]
                },
                {//design2
                    name:"Design 2",
                    description: "Design2",
                    maxHeight: "400px",
                    background: "#F8EDE3",
                    url: 'Images/Shops/Nile/DesignCluster1/Design2/Design2.svg',
                    
                    subShops:[ 
                    {
                      name: "SubShop1Button",
                      buttonText: "Design 2",
                      url: 'Images/Shops/Nile/DesignCluster1/Design2/Design2.svg',
                      height1: 360,
                      width1:300,
                      x1: 415,
                      y1: 150,
                      textHeight1: 480,
                      fontFamily: 'Times.ttf',
                      text1Visible: 'true',
                    },
                  ]
                },
                {//design3
                  name:"Design 3",
                  description: "Design3",
                  maxHeight: "400px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/DesignCluster1/Design3/SAE.svg',
                  designType: 'Triple',
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 3",
                    url: 'Images/Shops/Nile/DesignCluster1/Design3/Design3.svg',

                    height1: 280,
                    width1:300,
                    x1: 150,
                    y1: 180,
                    textHeight1: 370,

                    height2: 550,
                    width2:400,
                    x2: 375,
                    y2: 90,
                    textHeight2: 600,
                    
                    height3: 280,
                    width3:300,
                    x3: 720,
                    y3: 180,
                    textHeight3: 370,

                    fontFamily: 'Preciosa.ttf',
                    text1Visible: 'true',
                    text2Visible: 'true',
                    text3Visible: 'true',
                    designType: 'Triple',
                  },
                ]
                },
                {//design4
                    name:"Design 4",
                    description: "Design4",
                    maxHeight: "400px",
                    background: "#F8EDE3",
                    url: 'Images/Shops/Nile/DesignCluster1/Design4/Design4.svg',
                    
                    subShops:[ 
                    {
                      name: "SubShop1Button",
                      buttonText: "Design 1",
                      url: 'Images/Shops/Nile/DesignCluster1/Design4/Design4.svg',
                      height1: 200,
                      width1:150,
                      x1: 310,
                      y1: 400,

                      height2: 200,
                      width2:150,
                      x2: 587,
                      y2: 400,
                      textHeight1: 280,
                      textHeight2: 280,
                      fontFamily: 'Times.ttf',
                      text1Visible: 'true',
                      text2Visible: 'true',
                      designType: 'Double',
                    },
                  ]
                },
                {//design5
                    name:"Design 5",
                    description: "Design5",
                    maxHeight: "400px",
                    background: "#F8EDE3",
                    url: 'Images/Shops/Nile/DesignCluster1/Design5/Design5.svg',
                    subShops:[ 
                    {
                      name: "SubShop1Button",
                      buttonText: "Design 5",
                      url: 'Images/Shops/Nile/DesignCluster1/Design5/Design5.svg',
                      height1: 250,
                      width1:225,
                      x1: 425,
                      y1: 230,
                      textHeight1: 330,
                      fontFamily: 'Times.ttf',
                      text1Visible: 'true',
                    },
                  ]
                },
                {//design6
                  name:"Design 6",
                  description: "Design6",
                  maxHeight: "400px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/DesignCluster1/Design6/BSC.svg',
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 6",
                    url: 'Images/Shops/Nile/DesignCluster1/Design3/Design3.svg',

                    height1: 350,
                    width1:250,
                    x1: 180,
                    y1: 180,

                    height2: 550,
                    width2:400,
                    x2: 375,
                    y2: 90,
                    textHeight2: 550,
                    
                    height3: 350,
                    width3:250,
                    x3: 700,
                    y3: 180,

                    textHeight1: 480,
                   
                    textHeight3: 480,
                    fontFamily: 'Monogram KK.ttf',
                    text1Visible: 'true',
                    text2Visible: 'true',
                    text3Visible: 'true',
                    designType: 'Triple',
                  },
                ]
                },
                {//design7
                    name:"Design 7",
                    description: "Design7",
                    maxHeight: "300px",
                    background: "#F8EDE3",
                    url: 'Images/Shops/Nile/DesignCluster1/Design7/Design7.svg',
                    subShops:[ 
                    {
                      name: "SubShop1Button",
                      buttonText: "Design 7",
                      url: 'Images/Shops/Nile/DesignCluster1/Design7/Design7.svg',
                      height1: 320,
                      width1: 400,
                      x1: 335,
                      y1: 200,
                      textHeight1: 400,
                      fontFamily: 'Times.ttf',
                      text1Visible: 'true',
                    },
                  ]
                },
                {//design8
                  name:"Design 8",
                  description: "Design8",
                  maxHeight: "400px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/DesignCluster1/Design8/Design8s.svg',
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 8",
                    url: 'Images/Shops/Nile/DesignCluster1/Design8/Design3.svg',
                    
                    height1: 500,
                    width1:500,
                    x1: 340,
                    y1: 140,
                    textHeight1: 630,
            
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                ]
                },
                {//design9
                    name:"Design 9",
                    description: "Design9",
                    maxHeight: "300px",
                    background: "#F8EDE3",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/a.svg',
                    subShops:[ 
                    {
                      name: "SubShop1Button",
                      buttonText: "A",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/a.svg',
                      height1: 140,
                      width1: 781,
                      x1: 194,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "B",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/b.svg',
                      height1: 140,
                      width1: 781,
                      x1: 200,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "C",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/c.svg',
                      height1: 140,
                      width1: 781,
                      x1: 222,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "D",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/d.svg',
                      height1: 139,
                      width1: 781,
                      x1: 189,
                      y1: 367,
                      textHeight1: 219,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "E",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/e.svg',
                      height1: 140,
                      width1: 781,
                      x1: 189,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "F",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/f.svg',
                      height1: 140,
                      width1: 781,
                      x1: 152,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "G",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/g.svg',
                      height1: 140,
                      width1: 781,
                      x1: 222,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "H",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/h.svg',
                      height1: 140,
                      width1: 781,
                      x1: 242,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "I",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/i.svg',
                      height1: 140,
                      width1: 781,
                      x1: 194,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "J",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/j.svg',
                      height1: 140,
                      width1: 781,
                      x1: 200,
                      y1: 362,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "K",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/k.svg',
                      height1: 140,
                      width1: 781,
                      x1: 250,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "L",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/l.svg',
                      height1: 140,
                      width1: 781,
                      x1: 233,
                      y1: 369,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "M",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/m.svg',
                      height1: 140,
                      width1: 781,
                      x1: 194,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "N",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/n.svg',
                      height1: 140,
                      width1: 781,
                      x1: 228,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "O",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/o.svg',
                      height1: 140,
                      width1: 781,
                      x1: 240,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "P",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/p.svg',
                      height1: 139,
                      width1: 781,
                      x1: 175,
                      y1: 367,
                      textHeight1: 219,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "R",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/r.svg',
                      height1: 140,
                      width1: 781,
                      x1: 162,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "S",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/s.svg',
                      height1: 140,
                      width1: 781,
                      x1: 148,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "T",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/t.svg',
                      height1: 140,
                      width1: 781,
                      x1: 191,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "U",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/u.svg',
                      height1: 140,
                      width1: 781,
                      x1: 242,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "V",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/v.svg',
                      height1: 140,
                      width1: 781,
                      x1: 194,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "W",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/w.svg',
                      height1: 139,
                      width1: 781,
                      x1: 203,
                      y1: 392,
                      textHeight1: 219,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "X",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/x.svg',
                      height1: 140,
                      width1: 781,
                      x1: 194,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "Y",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/y.svg',
                      height1: 140,
                      width1: 781,
                      x1: 194,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                    {
                      name: "SubShop1Button",
                      buttonText: "Z",
                      url: 'Images/Shops/Nile/DesignCluster1/Design9/z.svg',
                      height1: 140,
                      width1: 781,
                      x1: 194,
                      y1: 332,
                      textHeight1: 220,
                      fontFamily: 'Times.ttf',
                    },
                  ]
                   
                },
                {//design10
                  name:"Design 10",
                  description: "Design10",
                  maxHeight: "400px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/DesignCluster1/Design10/Design10.svg',
                  designType: 'Triple',
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 6",
                    url: 'Images/Shops/Nile/DesignCluster1/Design3/Design3.svg',

                    height1: 350,
                    width1:250,
                    x1: 180,
                    y1: 189,
                    textHeight1: 440,

                    height2: 550,
                    width2:400,
                    x2: 375,
                    y2: 90,
                    textHeight2: 550,
                    
                    height3: 350,
                    width3:250,
                    x3: 700,
                    y3: 189,
                    textHeight3: 440,

                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                    text2Visible: 'true',
                    text3Visible: 'true',
                    designType: 'Triple',
                  },
                ]
                },
                {//design11
                  name:"Design 11",
                  description: "Design11",
                  maxHeight: "300px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/a.svg',
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "A",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/a.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "B",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/b.svg',
                    height1: 140,
                    width1: 781,
                    x1: 200,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "C",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/c.svg',
                    height1: 130,
                    width1: 781,
                    x1: 222,
                    y1: 338,
                    textHeight1: 200,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "D",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/d.svg',
                    height1: 139,
                    width1: 781,
                    x1: 189,
                    y1: 367,
                    textHeight1: 219,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "E",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/e.svg',
                    height1: 140,
                    width1: 781,
                    x1: 189,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "F",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/f.svg',
                    height1: 140,
                    width1: 781,
                    x1: 152,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "G",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/g.svg',
                    height1: 140,
                    width1: 781,
                    x1: 222,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "H",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/h.svg',
                    height1: 140,
                    width1: 781,
                    x1: 242,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "I",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/i.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "J",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/j.svg',
                    height1: 140,
                    width1: 781,
                    x1: 200,
                    y1: 362,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "K",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/k.svg',
                    height1: 140,
                    width1: 781,
                    x1: 250,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "L",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/L.svg',
                    height1: 140,
                    width1: 781,
                    x1: 233,
                    y1: 369,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "M",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/m.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "N",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/n.svg',
                    height1: 140,
                    width1: 781,
                    x1: 228,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "O",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/O.svg',
                    height1: 140,
                    width1: 781,
                    x1: 240,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "P",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/p.svg',
                    height1: 139,
                    width1: 781,
                    x1: 175,
                    y1: 367,
                    textHeight1: 219,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "R",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/r.svg',
                    height1: 140,
                    width1: 781,
                    x1: 162,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "S",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/s.svg',
                    height1: 140,
                    width1: 781,
                    x1: 148,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "T",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/t.svg',
                    height1: 140,
                    width1: 781,
                    x1: 191,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "U",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/u.svg',
                    height1: 140,
                    width1: 781,
                    x1: 242,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "V",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/v.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "W",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/w.svg',
                    height1: 139,
                    width1: 781,
                    x1: 203,
                    y1: 392,
                    textHeight1: 219,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "X",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/x.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "Y",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/y.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "Z",
                    url: 'Images/Shops/Nile/DesignCluster1/Design11/z.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 220,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                ]
                  
                },
                {//design12
                    name:"Design 12",
                    description: "Design12",
                    maxHeight: "400px",
                    background: "#F8EDE3",
                    url: 'Images/Shops/Nile/DesignCluster1/Design12/Design12.svg',
                    subShops:[ 
                    {
                      name: "SubShop1Button",
                      buttonText: "Design 12",
                      url: 'Images/Shops/Nile/DesignCluster1/Design12/Design12.svg',
                      height1: 360,
                      width1:300,
                      x1: 375,
                      y1: 180,
                      textHeight1: 430,
                      fontFamily: 'Times.ttf',
                      text1Visible: 'true',
                    },
                  ]
                },
                {//design13
                  name:"Design 13",
                  description: "Design13",
                  maxHeight: "400px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/DesignCluster1/Design13/Design13.svg',
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 13",
                    url: 'Images/Shops/Nile/DesignCluster1/Design8/Design3.svg',
                    
                    height1: 500,
                    width1:500,
                    x1: 340,
                    y1: 140,
                    textHeight1: 630,
            
                    fontFamily: 'TexasTangoBOLDPERSONALUSE.otf',
                    text1Visible: 'true',
                  },
                ]
                },
                {//design14
                  name:"Design 14",
                  description: "Design14",
                  maxHeight: "300px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/DesignCluster1/Design14/Design14.svg',
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 7",
                    url: 'Images/Shops/Nile/DesignCluster1/Design14/Design14.svg',
                    height1: 320,
                    width1: 400,
                    x1: 335,
                    y1: 195,
                    textHeight1: 400,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                ]
                },
                {//Free Fonts
                  name:"Fonts",
                  description: "Design15",
                  maxHeight: "400px",
                  background: "#F8EDE3",
                
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 8",
                    url: 'Images/Shops/Nile/DesignCluster1/EmptySvg.svg',         
                    height1: 500,
                    width1:1050,
                    x1: 1,
                    y1: 140,
                    textHeight1: 630,
                    
                    fontFamily: 'Times.ttf',
                    textAreaVisible: 'true',
                    buttonName: "Font 15 - Times"
                    
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 8",
                    height1: 500,
                    width1:1050,
                    x1: 1,
                    y1: 140,
                    textHeight1: 630,
                    url: 'Images/Shops/Nile/DesignCluster1/EmptySvg.svg',
                    fontFamily: 'Vivaldii.ttf',
                    textAreaVisible: 'true',
                    buttonName: "Font 16 - Vivaldi"
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 8",
                    url: 'Images/Shops/Nile/DesignCluster1/EmptySvg.svg',
                    height1: 500,
                    width1:1050,
                    x1: 1,
                    y1: 140,
                    textHeight1: 630,
                    
                    fontFamily: 'Rhapsoline Demo.ttf',
                    textAreaVisible: 'true',
                    buttonName: "Font 17 - Rhapsoline"
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 8",
                    url: 'Images/Shops/Nile/DesignCluster1/EmptySvg.svg',
                    height1: 500,
                    width1:1050,
                    x1: 1,
                    y1: 140,
                    textHeight1: 630,
                    
                    fontFamily: 'Rafreshline.otf',
                    textAreaVisible: 'true',
                    buttonName: "Font 18 - Rafreshline"
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 8",
                    url: 'Images/Shops/Nile/DesignCluster1/EmptySvg.svg',
                    height1: 500,
                    width1:1050,
                    x1: 1,
                    y1: 140,
                    textHeight1: 630,
                    
                    fontFamily: 'Palmtick.otf',
                    textAreaVisible: 'true',
                    buttonName: "Font 19 - Palmtick"
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 8",
                    url: 'Images/Shops/Nile/DesignCluster1/EmptySvg.svg',

                    height1: 500,
                    width1:1050,
                    x1: 1,
                    y1: 140,
                    textHeight1: 630,
                    
                    fontFamily: 'Cream Cake.ttf',
                    textAreaVisible: 'true',
                    buttonName: "Font 20 - Cream Cake"
                  },
                ]
                },
              ],
            },
            {//Vintage
              name: "Vintage",
              description: "Design Cluster 1",
              background: "#DFD3C3",
              hoverImageUrl: "Images/Shops/Nile/DesignCluster1/NILEDizaynKart.jpg",
              subShops: [
                {//VintageCassette
                  name:"Vintage Cassette",
                  description: "VintageCassette",
                  maxHeight: "400px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/Vintage/VintageCassette/VintageCassette.svg',
                  
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 1",
                    url: 'Images/Shops/Nile/Vintage/VintageCassette/VintageCassette.svg',
                    height1: 30,
                    width1:50,
                    x1: 347,
                    y1: 175,

                    textHeight1: 400,
                    
                    fontFamily: 'Gardaempty.ttf',
                    text1Visible: 'true',


                  },
                ]
                },
                {//VintageCizgili
                  name:"Vintage Cizgili",
                  description: "VintageCizgili",
                  maxHeight: "400px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/Vintage/VintageCizgili/VintageCizgili.svg',
                  
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 1",
                    url: 'Images/Shops/Nile/Vintage/VintageCizgili/VintageCizgili.svg',
                    height1: 360,
                    width1:1500,
                    x1: 252,
                    y1: 780,

                    textHeight1: 600,
                    
                    fontFamily: 'Prisma.ttf',
                    text1Visible: 'true',


                  },
                ]
                },

              ],
            },
           
          ],
        },
        {//Pretty
          name: "Pretty",
         
          background: "#F8EDE3",
          subShops: [
            {
              name: "Design Cluster 1",
              description: "Design Cluster 1",
            
              background: "#DFD3C3",
              subShops: [
              {//design1
                name:"Design 1",
                description: "Design1",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design1/Design1.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 1",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design1/Design1.svg',
                  height1: 90,
                  width1:90,
                  x1: 144,
                  y1: 117,
                  textHeight1: 160,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design2
                name:"Design 2",
                description: "Design2",
                maxHeight: "300px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design2/a.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "A",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/a.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "B",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/b.svg',
                  height1: 140,
                  width1: 781,
                  x1: 200,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "C",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/c.svg',
                  height1: 140,
                  width1: 781,
                  x1: 222,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "D",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/d.svg',
                  height1: 139,
                  width1: 781,
                  x1: 189,
                  y1: 367,
                  textHeight1: 219,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "E",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/e.svg',
                  height1: 140,
                  width1: 781,
                  x1: 189,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "F",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/f.svg',
                  height1: 140,
                  width1: 781,
                  x1: 152,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "G",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/g.svg',
                  height1: 140,
                  width1: 781,
                  x1: 222,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "H",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/h.svg',
                  height1: 140,
                  width1: 781,
                  x1: 242,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "I",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/i.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "J",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/j.svg',
                  height1: 140,
                  width1: 781,
                  x1: 200,
                  y1: 362,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "K",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/k.svg',
                  height1: 140,
                  width1: 781,
                  x1: 250,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "L",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/L.svg',
                  height1: 140,
                  width1: 781,
                  x1: 233,
                  y1: 369,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "M",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/m.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "N",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/n.svg',
                  height1: 140,
                  width1: 781,
                  x1: 228,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "O",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/o.svg',
                  height1: 140,
                  width1: 781,
                  x1: 240,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "P",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/p.svg',
                  height1: 139,
                  width1: 781,
                  x1: 175,
                  y1: 367,
                  textHeight1: 219,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "R",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/r.svg',
                  height1: 140,
                  width1: 781,
                  x1: 162,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "S",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/s.svg',
                  height1: 140,
                  width1: 781,
                  x1: 148,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "T",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/t.svg',
                  height1: 140,
                  width1: 781,
                  x1: 191,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "U",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/u.svg',
                  height1: 140,
                  width1: 781,
                  x1: 242,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "V",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/v.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "W",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/w.svg',
                  height1: 139,
                  width1: 781,
                  x1: 203,
                  y1: 392,
                  textHeight1: 219,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "X",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/x.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Y",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/y.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Z",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design2/z.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
                
              },
              {//design3
                name:"Design 3",
                description: "Design3",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design3/Design3.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 1",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design3/Design3.svg',
                  height1: 83,
                  width1:83,
                  x1: 144,
                  y1: 119,
                  textHeight1: 125,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design4
                name:"Design 4",
                description: "Design4",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design4/Design4.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 1",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design4/Design4.svg',
                  height1: 90,
                  width1:70,
                  x1: 150,
                  y1: 95,
                  height2: 90,
                  width2:70,
                  x2: 150,
                  y2: 183,
                  textHeight1: 120,
                  textHeight2: 120,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                  text2Visible: 'true',
                  designType: 'Double',
                },
              ]
              },
              {//design5
                name:"Design 5",
                description: "Design5",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design5/Design5s.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 5",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',
                  
                  height1: 500,
                  width1:500,
                  x1: 340,
                  y1: 140,
                  textHeight1: 630,
          
                  fontFamily: 'Carnevalee Freakshow.ttf',
                  text1Visible: 'true',
                },]
              },
              {//design6
                name:"Design 6",
                description: "Design6",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design6/Design6.svg',
                designType: 'Triple',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 6",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',

                  height1: 350,
                  width1:250,
                  x1: 180,
                  y1: 189,
                  textHeight1: 440,

                  height2: 550,
                  width2:400,
                  x2: 375,
                  y2: 90,
                  textHeight2: 550,
                  
                  height3: 350,
                  width3:250,
                  x3: 700,
                  y3: 189,
                  textHeight3: 440,

                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                  text2Visible: 'true',
                  text3Visible: 'true',
                  designType: 'Triple',
                },
              ]
              },
              {//design7
                name:"Design 7",
                description: "Design7",
                maxHeight: "300px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design7/Design7.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "A",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design7/Design7.svg',
                  height1: 30,
                  width1: 145,
                  x1: 101,
                  y1: 199,
                  textHeight1: 45,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                ]
                
              },
              {//design8
                name:"Design 8",
                description: "Design8",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design8/Design8.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 5",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',
                  
                  height1: 500,
                  width1:500,
                  x1: 340,
                  y1: 140,
                  textHeight1: 630,
          
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },]
              },
              {//design9
                name:"Design 9",
                description: "Design9",
                maxHeight: "300px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design9/Design9.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "A",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design9/Design9.svg',
                  height1: 90,
                  width1: 260,
                  x1: 49,
                  y1: 114,
                  textHeight1: 120,
                  fontFamily: 'Vivaldi Italic.ttf',
                  text1Visible: 'true',
                },
                ]
                
              },
              {//design10
                name:"Design 10",
                description: "Design2",
                maxHeight: "300px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design10/e.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "A",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/a.svg',
                  height1: 55,
                  width1: 390,
                  x1: 211,
                  y1: 332,
                  textHeight1: 70,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "B",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/b.svg',
                  height1: 43,
                  width1: 303,
                  x1: 176,
                  y1: 307,
                  textHeight1: 55,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "C",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/c.svg',
                  height1: 54,
                  width1: 444,
                  x1: 121,
                  y1: 307,
                  textHeight1: 73,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "D",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/d.svg',
                  height1: 52,
                  width1: 425,
                  x1: 176,
                  y1: 307,
                  textHeight1: 73,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "E",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/e.svg',
                  height1: 55,
                  width1: 445,
                  x1: 136,
                  y1: 306,
                  textHeight1: 76,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "F",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/f.svg',
                  height1: 44,
                  width1: 350,
                  x1: 156,
                  y1: 315,
                  textHeight1: 60,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "G",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/g.svg',
                  height1: 56,
                  width1: 440,
                  x1: 116,
                  y1: 305,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "H",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/h.svg',
                  height1: 56,
                  width1: 440,
                  x1: 116,
                  y1: 305,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "I",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/i.svg',
                  height1: 56,
                  width1: 440,
                  x1: 121,
                  y1: 306,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "J",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/j.svg',
                  height1: 56,
                  width1: 440,
                  x1: 121,
                  y1: 306,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "K",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/k.svg',
                  height1: 56,
                  width1: 440,
                  x1: 123,
                  y1: 306,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "L",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/l.svg',
                  height1: 56,
                  width1: 440,
                  x1: 127,
                  y1: 306,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "M",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/m.svg',
                  height1: 37,
                  width1: 295,
                  x1: 207,
                  y1: 310,
                  textHeight1: 50,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "N",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/n.svg',
                  height1: 39,
                  width1: 320,
                  x1: 222,
                  y1: 310,
                  textHeight1: 50,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "O",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/o.svg',
                  height1: 56,
                  width1: 430,
                  x1: 127,
                  y1: 306,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "P",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/p.svg',
                  height1: 46,
                  width1: 355,
                  x1: 162,
                  y1: 309,
                  textHeight1: 65,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "R",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/r.svg',
                  height1: 46,
                  width1: 355,
                  x1: 162,
                  y1: 307,
                  textHeight1: 65,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "S",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/s.svg',
                  height1: 55,
                  width1: 430,
                  x1: 152,
                  y1: 306,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "T",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/t.svg',
                  height1: 40,
                  width1: 330,
                  x1: 127,
                  y1: 308,
                  textHeight1: 50,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "U",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/u.svg',
                  height1: 56,
                  width1: 415,
                  x1: 137,
                  y1: 306,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "V",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/v.svg',
                  height1: 45,
                  width1: 350,
                  x1: 159,
                  y1: 307,
                  textHeight1: 70,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "W",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/w.svg',
                  height1: 44,
                  width1: 340,
                  x1: 227,
                  y1: 308,
                  textHeight1: 65,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "X",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/x.svg',
                  height1: 55,
                  width1: 427,
                  x1: 132,
                  y1: 306,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Y",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/y.svg',
                  height1: 55,
                  width1: 427,
                  x1: 114,
                  y1: 306,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Z",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design10/z.svg',
                  height1: 55,
                  width1: 420,
                  x1: 94,
                  y1: 306,
                  textHeight1: 80,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
                
              },
              {//design11
                  name:"Design 11",
                  description: "Design11",
                  maxHeight: "300px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/DesignCluster1/Design9/a.svg',
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "A",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/a.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "B",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/b.svg',
                    height1: 140,
                    width1: 781,
                    x1: 200,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "C",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/c.svg',
                    height1: 140,
                    width1: 781,
                    x1: 222,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "D",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/d.svg',
                    height1: 139,
                    width1: 781,
                    x1: 189,
                    y1: 367,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "E",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/e.svg',
                    height1: 140,
                    width1: 781,
                    x1: 189,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "F",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/f.svg',
                    height1: 140,
                    width1: 781,
                    x1: 152,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "G",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/g.svg',
                    height1: 140,
                    width1: 781,
                    x1: 222,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "H",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/h.svg',
                    height1: 140,
                    width1: 781,
                    x1: 242,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "I",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/i.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "J",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/j.svg',
                    height1: 140,
                    width1: 781,
                    x1: 200,
                    y1: 362,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "K",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/k.svg',
                    height1: 140,
                    width1: 781,
                    x1: 250,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "L",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/l.svg',
                    height1: 140,
                    width1: 781,
                    x1: 233,
                    y1: 369,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "M",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/m.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "N",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/n.svg',
                    height1: 140,
                    width1: 781,
                    x1: 228,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "O",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/o.svg',
                    height1: 140,
                    width1: 781,
                    x1: 240,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "P",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/p.svg',
                    height1: 139,
                    width1: 781,
                    x1: 175,
                    y1: 367,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "R",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/r.svg',
                    height1: 140,
                    width1: 781,
                    x1: 162,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "S",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/s.svg',
                    height1: 140,
                    width1: 781,
                    x1: 148,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "T",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/t.svg',
                    height1: 140,
                    width1: 781,
                    x1: 191,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "U",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/u.svg',
                    height1: 140,
                    width1: 781,
                    x1: 242,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "V",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/v.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "W",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/w.svg',
                    height1: 139,
                    width1: 781,
                    x1: 203,
                    y1: 392,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "X",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/x.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "Y",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/y.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                  {
                    name: "SubShop1Button",
                    buttonText: "Z",
                    url: 'Images/Shops/Nile/DesignCluster1/Design9/z.svg',
                    height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 210,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                  },
                ]
                 
              },
              {//design12
                name:"Design 12",
                description: "Design12",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design12/Design12.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 12",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',

                  height1: 350,
                  width1:250,
                  x1: 180,
                  y1: 180,

                  height2: 550,
                  width2:400,
                  x2: 375,
                  y2: 90,
                  textHeight2: 550,
                  
                  height3: 350,
                  width3:250,
                  x3: 700,
                  y3: 180,

                  textHeight1: 400,
                 
                  textHeight3: 400,
                  fontFamily: 'Monogram KK.ttf',
                  text1Visible: 'true',
                  text2Visible: 'true',
                  text3Visible: 'true',
                  designType: 'Triple',
                },
              ]
              },
              {//design13
                name:"Design 13",
                description: "Design13",
                maxHeight: "300px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design13/Design13.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 7",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design13/Design13.svg',
                  height1: 300,
                  width1: 380,
                  x1: 340,
                  y1: 215,
                  textHeight1: 400,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design14
                  name:"Design 14",
                  description: "Design14",
                  maxHeight: "400px",
                  background: "#F8EDE3",
                  url: 'Images/Shops/Nile/DesignCluster1/Design1/Design1.svg',
                  
                  subShops:[ 
                  {
                    name: "SubShop1Button",
                    buttonText: "Design 1",
                    url: 'Images/Shops/Nile/DesignCluster1/Design1/Design1.svg',
                    height1: 360,
                    width1:300,
                    x1: 252,
                    y1: 150,
                    height2: 360,
                    width2:300,
                    x2: 576,
                    y2: 150,
                    textHeight1: 400,
                    textHeight2: 400,
                    fontFamily: 'Times.ttf',
                    text1Visible: 'true',
                    text2Visible: 'true',
                    designType: 'Double',
                  },
                ]
              },
              {//Free Fonts
                name:"Fonts",
                description: "Design15",
                maxHeight: "400px",
                background: "#F8EDE3",
              
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 8",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',         
                  height1: 500,
                  width1:1050,
                  x1: 1,
                  y1: 140,
                  textHeight1: 630,
                  
                  fontFamily: 'Times.ttf',
                  textAreaVisible: 'true',
                  buttonName: "Font 15 - Times"
                  
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Design 8",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',         
                  height1: 500,
                  width1:1050,
                  x1: 1,
                  y1: 140,
                  textHeight1: 630,
                  
                  fontFamily: 'Rightwood.ttf',
                  textAreaVisible: 'true',
                  buttonName: "Font 16 - Rightwood"
                  
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Design 8",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',         
                  height1: 500,
                  width1:1050,
                  x1: 1,
                  y1: 140,
                  textHeight1: 630,
                  
                  fontFamily: 'Rossa.otf',
                  textAreaVisible: 'true',
                  buttonName: "Font 17 - Rossa"
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Design 8",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',         
                  height1: 500,
                  width1:1050,
                  x1: 1,
                  y1: 140,
                  textHeight1: 630,
                  
                  fontFamily: 'Corbel.ttf',
                  textAreaVisible: 'true',
                  buttonName: "Font 18 - Corbel"
                }, 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 8",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',         
                  height1: 500,
                  width1:1050,
                  x1: 1,
                  y1: 140,
                  textHeight1: 630,
                  
                  fontFamily: 'Austria Font.ttf',
                  textAreaVisible: 'true',
                  buttonName: "Font 19 - Austria"
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Design 8",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',         
                  height1: 500,
                  width1:1050,
                  x1: 1,
                  y1: 140,
                  textHeight1: 630,
                  
                  fontFamily: 'Cardinal.ttf',
                  textAreaVisible: 'true',
                  buttonName: "Font 20 - Cardinal"
                },
               
              ]
              },
              ],
            },
          
          ],
        },
        {//Styleather
          name: "Styleather",
          background: "#F8EDE3",
          subShops: [
            {//Temel Dizaynlar
              name: "Temel Dizaynlar",
              description: "Design Cluster 1",
              background: "#DFD3C3",
              subShops: [
              {//design1
              name:"Design 1",
              description: "Design7",
              maxHeight: "300px",
              background: "#F8EDE3",
              url: 'Images/Shops/Pretty/DesignCluster1/Design7/Design7.svg',
              subShops:[ 
              {
                name: "SubShop1Button",
                buttonText: "A",
                url: 'Images/Shops/Pretty/DesignCluster1/Design7/Design7.svg',
                height1: 30,
                width1: 145,
                x1: 101,
                y1: 199,
                textHeight1: 45,
                fontFamily: 'Times.ttf',
                text1Visible: 'true',
              },
              ]
              
              },
              {//design2
                name:"Design 2",
                description: "Design11",
                maxHeight: "300px",
                background: "#F8EDE3",
                url: 'Images/Shops/Nile/DesignCluster1/Design11/a.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "A",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/a.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "B",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/b.svg',
                  height1: 140,
                  width1: 781,
                  x1: 200,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "C",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/c.svg',
                  height1: 130,
                  width1: 781,
                  x1: 222,
                  y1: 338,
                  textHeight1: 200,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "D",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/d.svg',
                  height1: 139,
                  width1: 781,
                  x1: 189,
                  y1: 367,
                  textHeight1: 219,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "E",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/e.svg',
                  height1: 140,
                  width1: 781,
                  x1: 189,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "F",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/f.svg',
                  height1: 140,
                  width1: 781,
                  x1: 152,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "G",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/g.svg',
                  height1: 140,
                  width1: 781,
                  x1: 222,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "H",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/h.svg',
                  height1: 140,
                  width1: 781,
                  x1: 242,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "I",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/i.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "J",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/j.svg',
                  height1: 140,
                  width1: 781,
                  x1: 200,
                  y1: 362,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "K",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/k.svg',
                  height1: 140,
                  width1: 781,
                  x1: 250,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "L",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/L.svg',
                  height1: 140,
                  width1: 781,
                  x1: 233,
                  y1: 369,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "M",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/m.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "N",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/n.svg',
                  height1: 140,
                  width1: 781,
                  x1: 228,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "O",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/O.svg',
                  height1: 140,
                  width1: 781,
                  x1: 240,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "P",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/p.svg',
                  height1: 139,
                  width1: 781,
                  x1: 175,
                  y1: 367,
                  textHeight1: 219,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "R",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/r.svg',
                  height1: 140,
                  width1: 781,
                  x1: 162,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "S",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/s.svg',
                  height1: 140,
                  width1: 781,
                  x1: 148,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "T",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/t.svg',
                  height1: 140,
                  width1: 781,
                  x1: 191,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "U",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/u.svg',
                  height1: 140,
                  width1: 781,
                  x1: 242,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "V",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/v.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "W",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/w.svg',
                  height1: 139,
                  width1: 781,
                  x1: 203,
                  y1: 392,
                  textHeight1: 219,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "X",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/x.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Y",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/y.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Z",
                  url: 'Images/Shops/Nile/DesignCluster1/Design11/z.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
                
              },
              {//design3
                name:"Design 3",
                description: "Design3",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/TemelDizayn/Design3/Design3.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 4",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design3/Design3.svg',
                  height1: 34,
                  width1:179,
                  x1: 96,
                  y1: 249,
                  textHeight1: 48,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design4
                name:"Design 4",
                description: "Design3",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/TemelDizayn/Design4/Design4.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 4",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design4/Design4.svg',
                  height1: 120,
                  width1:100,
                  x1: 131,
                  y1: 109,
                  textHeight1: 180,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design5
                name:"Design 5",
                description: "Design5",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/TemelDizayn/Design5/Design5.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 4",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design5/Design5.svg',
                  height1: 30,
                  width1:130,
                  x1: 118,
                  y1: 168,
                  textHeight1: 40,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design6
                name:"Design 6",
                description: "Design6",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Nile/DesignCluster1/Design1/Design1.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 1",
                  url: 'Images/Shops/Nile/DesignCluster1/Design1/Design1.svg',
                  height1: 360,
                  width1:300,
                  x1: 252,
                  y1: 150,
                  height2: 360,
                  width2:300,
                  x2: 576,
                  y2: 150,
                  textHeight1: 400,
                  textHeight2: 400,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                  text2Visible: 'true',
                  designType: 'Double',
                },
              ]
              },
              {//design7
                name:"Design 7",
                description: "Design7",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design3/Design3.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 1",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design3/Design3.svg',
                  height1: 83,
                  width1:83,
                  x1: 144,
                  y1: 119,
                  textHeight1: 125,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design8
                name:"Design 8",
                description: "Design8",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/TemelDizayn/Design8/Design8.svg',
                designType: 'Triple',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 3",
                  url: 'Images/Shops/Styleather/TemelDizayn/EmptySvg.svg',

                  height1: 280,
                  width1:300,
                  x1: 150,
                  y1: 180,
                  textHeight1: 370,

                  height2: 550,
                  width2:400,
                  x2: 375,
                  y2: 90,
                  textHeight2: 600,
                  
                  height3: 280,
                  width3:300,
                  x3: 720,
                  y3: 180,
                  textHeight3: 370,

                  fontFamily: 'palmtickP.otf',
                  text1Visible: 'true',
                  text2Visible: 'true',
                  text3Visible: 'true',
                  designType: 'Triple',
                },
              ]
              },
              {//design9
                name:"Design 9",
                description: "Design9",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Pretty/DesignCluster1/Design8/Design8.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 5",
                  url: 'Images/Shops/Pretty/DesignCluster1/Design3/Design3.svg',
                  
                  height1: 500,
                  width1:500,
                  x1: 340,
                  y1: 140,
                  textHeight1: 630,
          
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },]
              },
              {//design10
                name:"Design 10",
                description: "Design10",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/TemelDizayn/Design10/Design10.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 4",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design10/Design10.svg',
                  height1: 70,
                  width1:140,
                  x1: 116,
                  y1: 147,
                  textHeight1: 100,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design11
                name:"Design 11",
                description: "Design2",
                maxHeight: "300px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/TemelDizayn/Design11/e.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "A",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/a.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "B",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/b.svg',
                  height1: 140,
                  width1: 781,
                  x1: 200,
                  y1: 332,
                  textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "C",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/c.svg',
                  height1: 140,
                    width1: 781,
                    x1: 222,
                    y1: 330,
                    textHeight1: 200,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "D",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/d.svg',
                  height1: 139,
                    width1: 781,
                    x1: 259,
                    y1: 332,
                    textHeight1: 190,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "E",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/e.svg',
                  height1: 140,
                    width1: 781,
                    x1: 189,
                    y1: 332,
                    textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "F",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/f.svg',
                  height1: 140,
                    width1: 781,
                    x1: 152,
                    y1: 332,
                    textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "G",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/g.svg',
                  height1: 140,
                    width1: 781,
                    x1: 222,
                    y1: 332,
                    textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "H",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/h.svg',
                  height1: 140,
                    width1: 781,
                    x1: 242,
                    y1: 332,
                    textHeight1: 220,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "I",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/i.svg',
                  height1: 140,
                  width1: 781,
                  x1: 196,
                  y1: 332,
                  textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "J",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/j.svg',
                  height1: 140,
                  width1: 781,
                  x1: 233,
                  y1: 332,
                  textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "K",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/k.svg',
                  height1: 140,
                  width1: 781,
                  x1: 250,
                  y1: 332,
                  textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "L",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/l.svg',
                  height1: 140,
                  width1: 781,
                  x1: 233,
                  y1: 334,
                  textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "M",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/m.svg',
                  height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 335,
                    textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "N",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/n.svg',
                  height1: 140,
                  width1: 781,
                  x1: 228,
                  y1: 335,
                  textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "O",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/o.svg',
                 height1: 140,
                    width1: 781,
                    x1: 240,
                    y1: 332,
                    textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "P",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/p.svg',
                  height1: 139,
                    width1: 781,
                    x1: 175,
                    y1: 332,
                    textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "R",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/r.svg',
                  height1: 140,
                  width1: 781,
                  x1: 162,
                  y1: 332,
                  textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "S",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/s.svg',
                 height1: 140,
                    width1: 781,
                    x1: 178,
                    y1: 332,
                    textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "T",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/t.svg',
                height1: 140,
                    width1: 781,
                    x1: 191,
                    y1: 332,
                    textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "U",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/u.svg',
                  height1: 140,
                  width1: 781,
                  x1: 242,
                  y1: 332,
                  textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "V",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/v.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "W",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/w.svg',
                  height1: 139,
                  width1: 781,
                  x1: 218,
                  y1: 327,
                  textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "X",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/x.svg',
              height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 332,
                    textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Y",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/y.svg',
                  height1: 140,
                    width1: 781,
                    x1: 194,
                    y1: 327,
                    textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Z",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/z.svg',
                  height1: 140,
                  width1: 781,
                  x1: 194,
                  y1: 332,
                  textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
                {
                  name: "SubShop1Button",
                  buttonText: "q",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design11/q.svg',
                  height1: 140,
                  width1: 781,
                  x1: 234,
                  y1: 332,
                  textHeight1: 210,
                  fontFamily: 'Times.ttf',
                  text1Visible: 'true',
                },
              ]
                
              },
              {//design12
                name:"Design 12",
                description: "Design2",
                maxHeight: "300px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/TemelDizayn/Design12/t.svg',
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "A",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/a.svg',
                  height1: 100,
                  width1: 515,
                  x1: 299,
                  y1: 375,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 7
                },
                {
                  name: "SubShop1Button",
                  buttonText: "B",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/b.svg',
                  height1: 100,
                  width1: 550,
                  x1: 259,
                  y1: 375,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "C",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/c.svg',
                  height1: 100,
                  width1: 550,
                  x1: 284,
                  y1: 375,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "D",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/d.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 400,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "E",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/e.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 345,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "F",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/f.svg',
                  height1: 100,
                  width1: 550,
                  x1: 299,
                  y1: 365,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "G",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/g.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 345,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "H",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/h.svg',
                  height1: 100,
                  width1: 550,
                  x1: 309,
                  y1: 370,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "I",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/i.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 345,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "J",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/j.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 335,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "K",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/k.svg',
                  height1: 100,
                  width1: 550,
                  x1: 239,
                  y1: 340,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "L",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/l.svg',
                  height1: 100,
                  width1: 550,
                  x1: 269,
                  y1: 330,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "M",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/m.svg',
                  height1: 100,
                  width1: 640,
                  x1: 254,
                  y1: 335,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 9
                },
                {
                  name: "SubShop1Button",
                  buttonText: "N",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/n.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 320,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "O",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/o.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 325,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "P",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/p.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 315,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "R",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/r.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 380,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "S",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/s.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 315,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "T",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/t.svg',
                  height1: 100,
                  width1: 550,
                  x1: 294,
                  y1: 355,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "U",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/u.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 330,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "V",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/v.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 320,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "W",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/w.svg',
                  height1: 100,
                  width1: 640,
                  x1: 274,
                  y1: 310,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 9
                },
                {
                  name: "SubShop1Button",
                  buttonText: "X",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/x.svg',
                    height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 345,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Y",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/y.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 345,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Z",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/z.svg',
                  height1: 100,
                  width1: 550,
                  x1: 274,
                  y1: 320,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
                {
                  name: "SubShop1Button",
                  buttonText: "q",
                  url: 'Images/Shops/Styleather/TemelDizayn/Design12/q.svg',
                  height1: 100,
                  width1: 550,
                  x1: 289,
                  y1: 325,
                  textHeight1: 150,
                  fontFamily: 'imprintmtshadow.ttf',
                  text1Visible: 'true',
                  rotationAngle: 6
                },
              ]
                
              },
              {//Free Fonts
                name:"Fonts",
                description: "Design15",
                maxHeight: "400px",
                background: "#F8EDE3",
              
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 8",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',         
                  height1: 500,
                  width1:1050,
                  x1: 1,
                  y1: 140,
                  textHeight1: 630,
                  
                  fontFamily: 'Carnevalee Freakshow.ttf',
                  textAreaVisible: 'true',
                  buttonName: "Font 13 - Carnevalee Freakshow"
                  
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Design 8",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',         
                  height1: 500,
                  width1:1050,
                  x1: 1,
                  y1: 140,
                  textHeight1: 630,
                  
                  fontFamily: 'Times.ttf',
                  textAreaVisible: 'true',
                  buttonName: "Font 14 - Times"
                  
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Design 8",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',         
                  height1: 500,
                  width1:1050,
                  x1: 1,
                  y1: 140,
                  textHeight1: 630,
                  
                  fontFamily: 'Simplesky.otf',
                  textAreaVisible: 'true',
                  buttonName: "Font 15 - Simplesky"
                },
                {
                  name: "SubShop1Button",
                  buttonText: "Design 8",
                  url: 'Images/Shops/Pretty/DesignCluster1/EmptySvg.svg',         
                  height1: 500,
                  width1:1050,
                  x1: 1,
                  y1: 140,
                  textHeight1: 630,
                  
                  fontFamily: 'Air Americana.ttf',
                  textAreaVisible: 'true',
                  buttonName: "Font 16 - Air Americana"
                }, 
               
              ]
              },
              ],
            },
            {//JewleryBox
              name: "Jewelry Box",
              description: "Design Cluster 1",
              background: "#DFD3C3",
              subShops: [

              {//design1
                name:"Design 1",
                description: "Design1",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design1/Design1.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 1",
                  url: 'Images/Shops/Styleather/JewelryCase/Design1/Design1.svg',
                  height1: 30,
                  width1:130,
                  x1: 216,
                  y1: 257,
                  textHeight1: 40,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              }, 
              {//design2
                name:"Design 2",
                description: "Design2",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design2/Design2.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 2",
                  url: 'Images/Shops/Styleather/JewelryCase/Design2/Design2.svg',
                  height1: 50,
                  width1:130,
                  x1: 216,
                  y1: 257,
                  textHeight1: 70,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design3
                name:"Design 3",
                description: "Design3",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design3/Design3.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 3",
                  url: 'Images/Shops/Styleather/JewelryCase/Design3/Design3.svg',
                  height1: 50,
                  width1:130,
                  x1: 201,
                  y1: 242,
                  textHeight1: 70,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design4
                name:"Design 4",
                description: "Design4",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design4/Design4.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 4",
                  url: 'Images/Shops/Styleather/JewelryCase/Design4/Design4.svg',
                  height1: 40,
                  width1:130,
                  x1: 201,
                  y1: 262,
                  textHeight1: 60,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design5
                name:"Design 5",
                description: "Design5",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design5/Design5.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 5",
                  url: 'Images/Shops/Styleather/JewelryCase/Design5/Design5.svg',
                  height1: 40,
                  width1:140,
                  x1: 191,
                  y1: 242,
                  textHeight1: 40,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design6
                name:"Design 6",
                description: "Design6",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design6/Design6.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 6",
                  url: 'Images/Shops/Styleather/JewelryCase/Design6/Design6.svg',
                  height1: 40,
                  width1:130,
                  x1: 206,
                  y1: 252,
                  textHeight1: 50,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design7
                name:"Design 7",
                description: "Design7",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design7/Design7.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 7",
                  url: 'Images/Shops/Styleather/JewelryCase/Design7/Design7.svg',
                  height1: 60,
                  width1: 92,
                  x1: 246,
                  y1: 237,
                  textHeight1: 50,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design8
                name:"Design 8",
                description: "Design8",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design8/Design8.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 8",
                  url: 'Images/Shops/Styleather/JewelryCase/Design8/Design8.svg',
                  height1: 55,
                  width1:120,
                  x1: 231,
                  y1: 247,
                  textHeight1: 55,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design9
                name:"Design 9",
                description: "Design9",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design9/Design9.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 9",
                  url: 'Images/Shops/Styleather/JewelryCase/Design9/Design9.svg',
                  height1: 60,
                  width1: 100,
                  x1: 241,
                  y1: 237,
                  textHeight1: 65,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design10
                name:"Design 10",
                description: "Design10",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design10/Design10.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 10",
                  url: 'Images/Shops/Styleather/JewelryCase/Design10/Design10.svg',
                  height1: 60,
                  width1: 100,
                  x1: 191,
                  y1: 257,
                  textHeight1: 65,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design11
                name:"Design 11",
                description: "Design11",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design11/Design11.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 11",
                  url: 'Images/Shops/Styleather/JewelryCase/Design11/Design11.svg',
                  height1: 50,
                  width1: 150,
                  x1: 191,
                  y1: 257,
                  textHeight1: 55,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              },
              {//design12
                name:"Design 12",
                description: "Design12",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase/Design12/Design12.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 12",
                  url: 'Images/Shops/Styleather/JewelryCase/Design12/Design12.svg',
                  height1: 50,
                  width1: 150,
                  x1: 186,
                  y1: 257,
                  textHeight1: 55,
                  fontFamily: 'Vintage Goods.ttf',
                  text1Visible: 'true',
                },
              ]
              },

              ],
            },
            {//JewleryBox2
              name: "Jewelry Box 2",
              description: "Design Cluster 1",
              background: "#DFD3C3",
              subShops: [

              {//design1
                name:"Design 1",
                description: "Design1",
                maxHeight: "400px",
                background: "#F8EDE3",
                url: 'Images/Shops/Styleather/JewelryCase2/Design/Design.svg',
                
                subShops:[ 
                {
                  name: "SubShop1Button",
                  buttonText: "Design 1",
                  url: 'Images/Shops/Styleather/JewelryCase2/Design/Design.svg',
                  height1: 150,
                  width1:178,
                  x1: 56,
                  y1: 57,
                  textHeight1: 180,
                  fontFamily: 'Calline.otf',
                  text1Visible: 'true',
                },
              ]
              }, 

              ],
            },
          
          ],
        },
      ]
    }
  ];
  